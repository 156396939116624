<template>
  <v-container>
    <v-card :loading="notificationSettingLoading">
      <validation-observer ref="observer">
        <form @submit.prevent="update">
          <NotificationSettings :settings.sync="settings" />
          <v-divider></v-divider>
          <!--          <OtherSettings :settings.sync="settings" />-->
          <!--          <v-divider></v-divider>-->
          <v-card-actions>
            <v-btn
              :loading="notificationSettingLoading"
              type="submit"
              class="my-2"
              block
              color="primary"
            >
              {{ $t("update") }}
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
import NotificationSettings from "@/components/layout/user/settings/NotificationSettings";
import OtherSettings from "@/components/layout/user/settings/OtherSettings";
import { mapGetters } from "vuex";
export default {
  name: "SettingPage",
  // eslint-disable-next-line vue/no-unused-components
  components: { OtherSettings, NotificationSettings },
  created() {
    this.getNotificationSettingsData();
  },
  computed: {
    ...mapGetters(["getSettingPreferences"])
  },
  data() {
    return {
      notificationSettingLoading: false,
      settings: {
        preferences: []
      }
    };
  },
  methods: {
    getNotificationSettingsData() {
      this.notificationSettingLoading = true;
      this.$store
        .dispatch("SETTINGS_PREFERENCES_GET")
        .then(() => {
          let settings = [];
          for (const setting of this.getSettingPreferences) {
            settings.push({
              ...setting,
              disabled: false
            });
          }
          this.settings.preferences = settings;
        })
        .finally(() => (this.notificationSettingLoading = false));
    },

    update() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }

        this.notificationSettingLoading = true;
        this.$store
          .dispatch("SETTINGS_PREFERENCES_UPDATE", {
            preferences: this.settings.preferences.map(item => {
              return { name: item.id, value: item.value };
            })
          })
          .finally(() => (this.notificationSettingLoading = false));
      });
    }
  }
};
</script>

<style scoped></style>
