<template>
  <SettingPage />
</template>

<script>
import SettingPage from "@/components/layout/user/SettingPage";
export default {
  name: "Setting",
  components: { SettingPage }
};
</script>

<style scoped></style>
